export default class reconsideration {
    constructor() {
        const plzReconsider = document.getElementById('reconResourceService');
        if (plzReconsider) {
            this.selectedService();
        }
    }

    selectedService() {
        console.log("Reconsideration added");
        //resource/service selected
        $("#reconResourceService").change(function(){
            var selectedOne = $(this).val();
            console.log(selectedOne);
            if(selectedOne === "Collection") {
                //reveal collection
                $("#Collection").removeClass("d-none");
                $("#Program").addClass("d-none");
                $("#Meeting").addClass("d-none");
                $("#Display").addClass("d-none");
                $("#Computer").addClass("d-none");

            } else if(selectedOne === "ProgramsEvents") {
                //reveal program
                $("#Collection").addClass("d-none");
                $("#Program").removeClass("d-none");
                $("#Meeting").addClass("d-none");
                $("#Display").addClass("d-none");
                $("#Computer").addClass("d-none");

            } else if(selectedOne === "MeetingRoom"){
                //reveal meeting
                $("#Collection").addClass("d-none");
                $("#Program").addClass("d-none");
                $("#Meeting").removeClass("d-none");
                $("#Display").addClass("d-none");
                $("#Computer").addClass("d-none");

            } else if (selectedOne === "DisplayExhibit") {
                //reveal display
                $("#Collection").addClass("d-none");
                $("#Program").addClass("d-none");
                $("#Meeting").addClass("d-none");
                $("#Display").removeClass("d-none");
                $("#Computer").addClass("d-none");


            } else if (selectedOne === "ComputerOnline") {
                //reveal computer
                $("#Collection").addClass("d-none");
                $("#Program").addClass("d-none");
                $("#Meeting").addClass("d-none");
                $("#Display").addClass("d-none");
                $("#Computer").removeClass("d-none");

            } else {
                //hide em all
                $("#Collection").addClass("d-none");
                $("#Program").addClass("d-none");
                $("#Meeting").addClass("d-none");
                $("#Display").addClass("d-none");
                $("#Computer").addClass("d-none");
            }

        });

    }
}