/*all locations google map */

export default class mapAll {
    constructor() {
        const allLocations = document.getElementById('mapAll');

        if (allLocations) {
            this.allLocationsMap();
        }
    }

    allLocationsMap() {
        console.log("It's mapping time!");

        /**
         * @license
         * Copyright 2019 Google LLC. All Rights Reserved.
         * SPDX-License-Identifier: Apache-2.0
         * updates to for the county library version by Ariane Dupaix 5/2024
         */
        async function initMap() {
            // Request needed libraries.
            const {Map, InfoWindow} = await google.maps.importLibrary("maps");
            const {AdvancedMarkerElement, PinElement} = await google.maps.importLibrary(
                "marker",
            );
            const center = {lat: 40.621000, lng: -111.937993};
            const map = new Map(document.getElementById("map-canvas"), {
                zoom: 11,
                center,
                mapId: "slco-library-all-branches",
            });

            for (const property of properties) {
                const AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    content: buildContent(property),
                    position: property.position,
                    title: property.title,
                });

                AdvancedMarkerElement.addListener("click", () => {
                    toggleHighlight(AdvancedMarkerElement, property);
                });
            }
        }

        function toggleHighlight(markerView, property) {
            if (markerView.content.classList.contains("highlight")) {
                markerView.content.classList.remove("highlight");
                markerView.zIndex = null;
            } else {
                markerView.content.classList.add("highlight");
                markerView.zIndex = 1;
            }
        }

        function buildContent(property) {
            const content = document.createElement("div");

            content.classList.add("property");
            content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-${property.type}" title="${property.title}"></i><br />
        <span class="fa-sr-only">${property.title}</span>
    </div>
    <img class="location-image" src="${property.img}" />
    <div class="details">
        <div class="location-name">${property.branch}</div>
        <div class="location-alert text-danger">${property.alert}</div>
        <div class="location-address"><a href="${property.url}" target="_blank">${property.address}</a></div>
    </div>
    `;
            return content;
        }

        const properties = [
            {
                title: "Bing. Creek",
                branch: "Bingham Creek Library",
                alert: "",
                address: "4834 West 9000 South, West Jordan, UT 84081",
                url: "https://www.google.com/maps/search/?api=1&query=bingham+creek+library",
                img: "https://static.libnet.info/images/locations/slcls/Bingham%20Creek%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.587911,
                    lng: -112.006046,
                },
            },
            {
                title: "Daybreak",
                branch: "Daybreak Library",
                alert: "",
                address: "11358 Grandville Ave, South Jordan, UT 84009",
                url: "https://www.google.com/maps/search/?api=1&query=darybreak+library",
                img: "https://static.libnet.info/images/locations/slcls/Daybreak%20Exterior%202.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.5447585,
                    lng: -112.0152786,
                },
            },
            {
                title: "Draper",
                branch: "Draper Library",
                alert: "",
                address: "1136 East Pioneer Draper, UT 84020",
                url: "https://www.google.com/maps/search/?api=1&query=draper+library",
                img: "https://static.libnet.info/images/locations/slcls/Draper%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.524480,
                    lng: -111.858580,
                },
            },
            {
                title: "Granite",
                branch: "Granite Library",
                alert: "",
                address: "3331 South 500 East, South Salt Lake City, UT 84106",
                url: "https://www.google.com/maps/search/?api=1&query=granite+library",
                img: "https://static.libnet.info/images/locations/slcls/Granite%20Exterior%202.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.69914044378607,
                    lng: -111.87637892226579,
                },
            },
            {
                title: "Herriman",
                branch: "Herriman Library",
                alert: "",
                address: "5380 West Main Street, Herriman, UT 84096",
                url: "https://www.google.com/maps/search/?api=1&query=herriman+library",
                img: "https://static.libnet.info/images/locations/slcls/Herriman%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.515750,
                    lng: -112.019600,
                },
            },
            {
                title: "Holladay",
                branch: "Holladay Library",
                alert: "",
                address: "2150 East Murray Holladay Rd, Holladay, UT 84117",
                url: "https://www.google.com/maps/search/?api=1&query=holladay+library",
                img: "https://static.libnet.info/images/locations/slcls/Holladay%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.666681,
                    lng: -111.829078,
                },
            },
            {
                title: "Hunter",
                branch: "Hunter Library",
                alert: "",
                address: "4740 West 4100 South, West Valley City, UT 84120",
                url: "https://www.google.com/maps/search/?api=1&query=hunter+library",
                img: "https://static.libnet.info/images/locations/slcls/Hunter%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.682058,
                    lng: -112.003649,
                },
            },
            {
                title: "Kearns",
                branch: "Kearns Library",
                alert: "",
                address: "4275 West 5345 South, Kearns, UT 84118",
                url: "https://www.google.com/maps/search/?api=1&query=kearns+library",
                img: "https://static.libnet.info/images/locations/slcls/kearns.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.6532,
                    lng: -111.9933,
                },
            },
            {
                title: "Magna",
                branch: "Magna Library",
                alert: "",
                address: "2675 S 8950 W, Magna, UT 84044",
                url: "https://www.google.com/maps/search/?api=1&query=magna+library",
                img: "https://static.libnet.info/images/locations/slcls/Magna%20Exterior%202.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.711100,
                    lng: -112.104700,
                },
            },
            {
                title: "Millcreek",
                branch: "Millcreek Community Library",
                alert: "Closed September 9–October 14 for maintenance.",
                address: "2266 E Evergreen Ave, Millcreek, UT 84109",
                url: "https://www.google.com/maps/search/?api=1&query=millcreek+library",
                img: "https://static.libnet.info/images/locations/slcls/Millcreek%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.696727,
                    lng: -111.825539,
                },
            },
            {
                title: "Riverton",
                branch: "Riverton Library",
                alert: "",
                address: "12877 South 1830 West, Riverton, UT 84065",
                url: "https://www.google.com/maps/search/?api=1&query=riverton+library",
                img: "https://static.libnet.info/images/locations/slcls/Riverton%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.517330,
                    lng: -111.941139,
                },
            },
            {
                title: "R.V. Tyler",
                branch: "Ruth Vine Tyler Library",
                alert: "",
                address: "8041 S. Wood Street, Midvale, UT 84047",
                url: "https://www.google.com/maps/search/?api=1&query=ruth+vine+tyler+library",
                img: "https://static.libnet.info/images/locations/slcls/Tyler%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.605313,
                    lng: -111.893310,
                },
            },
            {
                title: "Sandy",
                branch: "Sandy Library",
                alert: "",
                address: "10100 South Petunia Way, Sandy, UT 84092",
                url: "https://www.google.com/maps/search/?api=1&query=sandy+library",
                img: "https://static.libnet.info/images/locations/slcls/Sandy%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.567433,
                    lng: -111.851611,
                },
            },
            {
                title: "S. Jordan",
                branch: "South Jordan Library",
                alert: "",
                address: "10673 S Redwood Road, South Jordan UT 84095",
                url: "https://www.google.com/maps/search/?api=1&query=south+jordan+library",
                img: "https://static.libnet.info/images/locations/slcls/South%20Jordan%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.557300,
                    lng: -111.936954,
                },
            },
            {
                title: "Taylorsville",
                branch: "Taylorsville Library",
                alert: "",
                address: "4870 South 2700 West, Taylorsville, UT 84129",
                url: "https://www.google.com/maps/search/?api=1&query=taylorsville+library",
                img: "https://static.libnet.info/images/locations/slcls/Taylorsville%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.663512,
                    lng: -111.957993,
                },
            },
            {
                title: "W. Jordan",
                branch: "West Jordan Library",
                alert: "",
                address: "8030 South 1825 West, West Jordan, UT 84088",
                url: "https://www.google.com/maps/search/?api=1&query=west+jordan+library",
                img: "https://static.libnet.info/images/locations/slcls/West%20Jordan%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.604800,
                    lng: -111.941900,
                },
            },
            // {
            //     title: "Viridian",
            //     branch: "Library's Viridian Event Center",
            //     alert: "",
            //     address: "8030 South 1825 West, West Jordan, UT 84088",
            //     url: "https://www.google.com/maps/search/?api=1&query=40.605180845111846%2C-111.94348935500567",
            //     img: "https://www.slcolibrary.org/sebin/v/u/westJordanNewSmall.jpg",
            //     type: "book-open-reader",
            //     position: {
            //         lat: 40.60513991804938,
            //         lng: -111.94339302493206,
            //     },
            // },
            {
                title: "W. Valley",
                branch: "West Valley Library",
                alert: "",
                address: "2880 West 3650 South, West Valley City, UT 84119",
                url: "https://www.google.com/maps/search/?api=1&query=west+valley+library",
                img: "https://static.libnet.info/images/locations/slcls/West%20Valley%20Interior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.692976,
                    lng: -111.961397,
                },
            },
            {
                title: "Whitmore",
                branch: "Whitmore Library",
                alert: "",
                address: "2197 East Fort Union Boulevard, Cottonwood Heights, UT 84121",
                url: "https://www.google.com/maps/search/?api=1&query=whitmore+library",
                img: "https://static.libnet.info/images/locations/slcls/Whitmore%20Exterior%201.jpg",
                type: "book-open-reader",
                position: {
                    lat: 40.623974,
                    lng: -111.827312,
                },
            },
        ];

        initMap();


    }

}

