/* fancy box enabling */

export default class boxesFancy{
    constructor(){
        const fancyBoxing = document.getElementById("fancybox");

        if (fancyBoxing){
            this.boxFancy();
        }
    }

    boxFancy(){
        console.log("Fancybox is enabled");

        Fancybox.bind('[data-fancybox]', {
            // Your custom options
        });
    }
}

